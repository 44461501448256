@import '../style-variables/sizes';

.keb-ge__page {
  padding: $PagePadding;

  &--pad {
    &-none {
      padding: 0;
    }

    &-v {
      padding: $PagePadding 0;
    }

    &-h {
      padding: 0 $PagePadding;
    }
  }
}
