// @import '@kerberos-compliance/kerberos-fe-lib/assets/keb-styles/themes/kerberos.main';
@import 'global-styles';


@import '@kerberos-compliance/kerberos-fe-lib/assets/keb-styles/themes/kerberos/index';
@import '@angular/cdk/overlay-prebuilt.css';
@import '@angular/cdk/text-field-prebuilt.css';
@import '@kerberos-compliance/kerberos-fe-lib/assets/keb-styles/global-styles/index';
@import '@kerberos-compliance/kerberos-fe-lib/assets/keb-styles/themes/common/index';
@import '@kerberos-compliance/kerberos-fe-lib/assets/keb-styles/themes/kerberos/index';
@import '@kerberos-compliance/kerberos-fe-lib/assets/keb-styles/helpers';
@import 'bootstrap/dist/css/bootstrap.min.css';

.keb-kerberos-theme {
  // Exports each color in map as cssVariable
  // Exports each color and as RGB e.g. var(--gray-rgb)
  @include to-css-variables($kerberos-colors);

  --safe-area-inset-top: 0;
  --safe-area-inset-bottom: 0;

  /* iOS Safari 11.4+, Safari 11.1+, Chrome 69+, Opera 56+ */
  // noinspection CssInvalidFunction
  @supports (height: env(safe-area-inset-top)) {
    --safe-area-inset-top: env(safe-area-inset-top, 0);
    --safe-area-inset-bottom: env(safe-area-inset-bottom, 0);
  }
}

body.keb-kerberos-theme {
  color: var(--font-primary);
  font-family: $FontFamilyDefault;
  background-color: var(--background-color);
}


body {
  min-width: 28.125rem; // 450px
  font-size: 0.857rem;
}

.keb-kerberos-theme {
  --f-red-rgb: 244, 84, 29;
}

// BOOSTRATP OVERRIDE
:root {
  --bs-blue: #063aa8;
  --bs-primary: #063aa8;
}
.text-primary {
  color: var(--brand-main);
}
.btn-primary {
  color: var(--white);
  background-color: var(--brand-main);
  border-color: var(--brand-main);
}
